import React, {FC} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';

type Complaints = {
  allComplaintsYaml: {
    edges: {
      node: {
        title: string;
        content: string;
      };
    }[];
  };
};

const query = graphql`
  query {
    allComplaintsYaml {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`;

const Complaints: FC = () => {
  const data = useStaticQuery<Complaints>(query);

  return (
    <Layout>
      <Container>
        <PageTitle title={data.allComplaintsYaml.edges[0].node.title} />
        <div
          style={{fontSize: '1.5rem'}}
          dangerouslySetInnerHTML={{__html: data.allComplaintsYaml.edges[0].node.content}}
        />
      </Container>
    </Layout>
  );
};

export default Complaints;
